import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RestaurantsService } from '../../services/restaurants.service';
import { StatisticsParams, Venue } from '../../utils/CommonInterfaces';

import * as moment from 'moment';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent implements OnInit, AfterViewInit, OnDestroy {
  private restaurantServiceSubscription: Subscription;
  private rightSidebarStatusSubscription: Subscription;

  public isFullScreen = false;
  public formatStartDate: string;
  public venueData: Venue;

  public statisticsParams: StatisticsParams = {
    selectedDate: undefined, // Todays date by default, after that can only be changed by the user
    // When on the day view, selectedDate === to actual selected date
    // When on the week view, selectedDate === to start of the week date
    // eg it is start of the range

    periodType: undefined, // day, week, month, year, specificRange
    compareWithType: undefined, // lastPeriod, specificRange

    periodStartDate: undefined, // can only be defined by user if periodType === specificRange
    periodEndDate: undefined, // can only be defined by user if periodType === specificRange

    periodStartDateEpoch: undefined, // can only be defined by user if periodType === specificRange
    periodEndDateEpoch: undefined, // can only be defined by user if periodType === specificRange

    compareStartDate: undefined, // can only be defined by user if compareWithType === specificRange
    compareEndDate: undefined, // can only be defined by user if compareWithType === specificRange

    totalGapStart: undefined, // earliest date from periodStartDate and compareStartDate
    totalGapEnd: undefined, // latest date from periodEndDate and compareEndDate

    weekDaysSelected: ['1', '2', '3', '4', '5', '6', '0'], // array of days of the week selected by the user
    timeslotsSelected: ['Lunch', 'Interpeak', 'Dinner'], // array of timeslots selected by the user
    viewType: undefined, // weekly, daily, live
    groupBy: 'day', // week, day
  };
  public showDateAlertMessage: boolean = false;

  public errors: any;
  public brandVenues = [];
  public selectedVenue = undefined;
  public grafanaDashboards: any;
  public flipped = false;
  public rightSidebarStatus = false;
  public secureToken: string;
  public selectedGrafanaId: string;
  public statisticType: string = 'current_week';
  public viewType: string = 'weekly';
  public compareWithDisplayFields: string[];

  constructor(
    private router: Router,
    private restaurantsService: RestaurantsService
  ) {



    for (const viewType of ['weekly', 'daily', 'live', 'trends', 'cross', 'export', 'competition', 'overview']) {
      if (this.router.url.includes(viewType)) {
        this.viewType = viewType;
        this.statisticsParams.viewType = viewType;
        break;
      }
    }

    switch(this.viewType){
      case 'trends':
        this.compareWithDisplayFields = ['trends', 'weekSelect', 'weekdaySelector', 'timeslotSelector'];
        break;
      case 'export':
        this.compareWithDisplayFields = ['trends', 'weekSelect', 'weekdaySelector', 'timeslotSelector', 'groupbySelector'];
        break;
      case 'overview':
        this.compareWithDisplayFields = [];
        break;
      case 'competition':
        this.compareWithDisplayFields = ['weekSelect'];
        break;
      default:
        this.compareWithDisplayFields = ['weekSelect', 'weekdaySelector', 'timeslotSelector'];
    }

  }

  /**
   * Returns the passed date string in the format `DD/MM/YYYY`
   * @param date
   * @returns
   */
  formatDateToDDMMYYYY(date: string) {
    return moment(date).format('DD/MM/YYYY');
  }

  /**
   * Sets `statisticsParams.selectedDate` to `venueData.latestDataDate`
   *
   * @returns
   */
  loadLatestDate() {
    if (!this.venueData) return;

    this.setAndValidateVenueDate(this.venueData, true);
  }

  /**
   * Returns the period type of the passed date
   *
   * @param date
   * @returns
   */
  getPeriodType(date: string) {
    const dateMoment = moment(date);

    if (
      dateMoment.isBetween(
        moment().startOf('isoWeek'),
        moment().endOf('isoWeek'),
        'days',
        '[]'
      )
    ) {
      return 'current_week';
    }

    if (
      dateMoment.isBetween(
        moment().subtract(1, 'weeks').startOf('isoWeek'),
        moment().subtract(1, 'weeks').endOf('isoWeek'),
        'days',
        '[]'
      )
    ) {
      return 'last_week';
    }

    // if (
    //   dateMoment.isBetween(
    //     moment(date).startOf('isoWeek'),
    //     moment(date).endOf('isoWeek'),
    //     'days',
    //     '[]'
    //   )
    // ) {
    //   return 'specific_week';
    // }

    return 'specific_week';
  }

  /**
   * Updates the `statisticsParams.selectedDate`
   * and checks if the selected date is in the future
   * if so display view latest data available message with CTA
   *
   * It also updates the `selectedVenue` and `selectedGrafanaId`
   *
   * @param venueData
   * @returns
   */
  setAndValidateVenueDate(venueData: Venue, reset: boolean = false) {
    if (this.statisticsParams.selectedDate && !reset) {
      const compareSelectedDate = moment(
        this.statisticsParams.selectedDate,
        'DD/MM/YYYY'
      );
      const comparePeriodStartDate = moment(
        this.statisticsParams.periodStartDate,
        'DD/MM/YYYY'
      );
      const comparePeriodEndDate = moment(
        this.statisticsParams.periodEndDate,
        'DD/MM/YYYY'
      );

      switch (this.statisticsParams.viewType) {
        case 'daily':
          if (compareSelectedDate.isAfter(venueData.latestDataDate, 'days')) {
            this.showDateAlertMessage = true;
            return;
          }
          break;
        case 'weekly':
          if (!moment(venueData.latestDataDate).isBetween(
              comparePeriodStartDate,
              comparePeriodEndDate,
              'days',
              '[]'
            )
          ) {
            if (compareSelectedDate.isAfter(venueData.latestDataDate, 'days')){
              if(!moment(venueData.latestDataDate).isBetween(
                  comparePeriodStartDate,
                  comparePeriodEndDate,
                  'days',
                  '[]'
              )){
                console.log('this alert');
                this.showDateAlertMessage = true;
                return;
              }
            }
          }
          break;
        default:
          break;
      }
    } else {
      // console.log('this.statisticsParams.viewType', this.statisticsParams.viewType);
      const startWeek = this.statisticsParams.viewType === 'trends' ?
          moment(venueData.latestDataDate).startOf('isoWeek').subtract('4', 'weeks') :
          moment(venueData.latestDataDate).startOf('isoWeek');
      const endWeek = moment(venueData.latestDataDate).endOf('isoWeek');

      this.statisticsParams = {
        ...this.statisticsParams,
        selectedDate: moment(venueData.latestDataDate, 'YYYY-MM-DD'),
        // periodType: 'current_week',
        periodType: this.getPeriodType(venueData.latestDataDate),
        periodStartDate: startWeek,
        periodEndDate: endWeek,
        periodStartDateEpoch: startWeek.valueOf(),
        periodEndDateEpoch: endWeek.startOf('day').add(1, 'hour').valueOf(),
      };
    }

    this.showDateAlertMessage = false;

    const venueId = venueData.id;
    if (venueId !== this.selectedVenue && this.selectedVenue !== undefined) {
      this.selectedVenue = venueId;
      this.selectedGrafanaId = venueId;
    }

    console.log('setAndValidateVenueDate()', this.statisticsParams);
  }

  /**
   * Reacts to date change in the date picker
   * found in `compare-with.component`
   *
   * @param event
   */
  onReceiveDateChange(event: {
    selectedDate: moment.Moment;
    periodType: string;
    periodStartDate: moment.Moment;
    periodEndDate: moment.Moment;
    periodStartDateEpoch: number;
    periodEndDateEpoch: number;
    weekDaysSelected: string[];
    timeslotsSelected: string[];
    groupBy: string;
  }) {
    if (!this.venueData) return;

    this.statisticsParams = {
      ...this.statisticsParams,
      selectedDate: moment(event.selectedDate),
      periodType: event.periodType,
      // periodType: this.getPeriodType(event.selectedDate.format('YYYY-MM-DD')),
      periodStartDate: moment(event.periodStartDate),
      periodEndDate: moment(event.periodEndDate),
      periodStartDateEpoch: moment(event.periodStartDate).valueOf(),
      periodEndDateEpoch: moment(event.periodEndDate)
        .startOf('day')
        .add(1, 'hour')
        .valueOf(),
      weekDaysSelected: event.weekDaysSelected,
      timeslotsSelected: event.timeslotsSelected,
      groupBy: event.groupBy,
    };

    console.log('statisticsParams onReceiveDateChange', this.statisticsParams);
    this.setAndValidateVenueDate(this.venueData);
  }

  openSidebar() {
    this.restaurantsService.rightSidebarSubject.next({ status: true });
  }

  ngOnInit() {
    this.restaurantServiceSubscription =
      this.restaurantsService.selectedVenueDataSubject.subscribe(
        (venueData) => {
          if (!venueData) return;


          // TODO timeslots definition should be coming from the database
          venueData.timeslots = {
            Lunch: [
              ['11', '00'],
              ['14', '00'],
            ],
            Interpeak: [
              ['14', '00'],
              ['18', '00'],
            ],
            Dinner: [
              ['18', '00'],
              ['23', '00'],
            ],
          };

          this.venueData = venueData;
          // Check if the statisticsParams backup has data
          // if os use it to set the statisticsParams
          if (
            Object.keys(
              this.restaurantsService.statisticParamsBackupData[this.viewType]
            ).length > 0
          ) {
            this.statisticsParams = {
              ...this.restaurantsService.statisticParamsBackupData[
                this.viewType
              ],
            };
            return;
          }
          this.setAndValidateVenueDate(venueData);
        }
      );

    this.rightSidebarStatusSubscription =
      this.restaurantsService.rightSidebarSubject.subscribe((data) => {
        if (data.status === 'force-off') {
          this.rightSidebarStatus = false;
        } else {
          this.rightSidebarStatus = !this.rightSidebarStatus;
        }
      });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    this.restaurantsService.statisticParamsBackupData = {
      [this.viewType]: this.statisticsParams,
    };

    // this.rightSidebarStatus = false;
    this.rightSidebarStatusSubscription.unsubscribe();
    this.restaurantServiceSubscription.unsubscribe();
  }
}
