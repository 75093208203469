import {
  Component,
  EventEmitter, Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  DateRange,
  StatisticsParams,
  Venue,
} from '../../../../utils/CommonInterfaces';
import { RestaurantsService } from '../../../../services/restaurants.service';
import {HttpClient} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgStrapiAuthConfig} from '../../../../services/strapi/auth/ng-strapi-auth-config';
@Component({
  selector: 'app-export-view',
  templateUrl: './export-view.component.html',
  styleUrls: ['./export-view.component.scss'],
})
export class ExportViewComponent implements OnChanges {
  @Input() statisticsParams: StatisticsParams;
  @Input() venueData: Venue;

  @Output() weekDaysSelectedChange = new EventEmitter<{}>();
  @Output() openSidebarEvent = new EventEmitter();

  public dateRange: DateRange;
  public weekDaysSelected: string[] = [];
  public weekDaysSelectedOld: string[] = [];
  public allVenues: Venue[] = [];
  public exportGroup: Venue[] = [];
  private apiUrl: string;
  public isDemoPos = false;
  exportInProgress = false;

  constructor(private restaurantsService: RestaurantsService,
              private httpClient: HttpClient,
              @Inject('config') private config: NgStrapiAuthConfig) {
    if (this.config && this.config.apiUrl) {
      this.apiUrl = this.config.apiUrl;
    } else {
      const err = '[NgStrapiAuth]: no api url provided';
      console.error(err);
      throw new Error('[NgStrapiAuth]: no api url provided');
    }
  }

  openRightSidebar() {
    this.openSidebarEvent.emit({});
  }

  selectionTrendsGroup($event) {
    this.exportGroup = [...$event.value];
  }

  groupByChange($event) {
    console.log('groupByChange', $event);
  }

  ngOnChanges(change: SimpleChanges): void {
    const { statisticsParams } = change;
    this.weekDaysSelected = [...this.statisticsParams.weekDaysSelected];
    this.allVenues = [...this.restaurantsService.allVenuesData];
    console.log('this.allVenues', this.allVenues);
    this.isDemoPos = '999991' === this.allVenues[0].posId;

    if (statisticsParams && statisticsParams.firstChange) {
      this.exportGroup.push(...this.allVenues);
    }

    if (statisticsParams && statisticsParams.currentValue.selectedDate) {
      const start = statisticsParams.currentValue.periodStartDate.clone();
      const end = statisticsParams.currentValue.periodEndDate.clone();

      this.dateRange = {
        start: start.format('DD/MM/YYYY'),
        end: end.format('DD/MM/YYYY'),
      };
    }
  }

  async downloadXLSX() {
    this.exportInProgress = true;
    const fileName = `eyezilla-export-${this.statisticsParams.periodStartDate.format('DD-MM-YYYY')}--${this.statisticsParams.periodEndDate.format('DD-MM-YYYY')}`;
    const payload = {
      'data': {
        'venues': this.exportGroup.map((venue) => venue.venueId),
        'variables': {
          'startDate': this.statisticsParams.periodStartDate.format('YYYY-MM-DD'),
          'endDate': this.statisticsParams.periodEndDate.format('YYYY-MM-DD'),
          'groupBy': this.statisticsParams.groupBy,
          'weekdays': this.statisticsParams.weekDaysSelected.map((day) => Number(day)),
          'timeGaps': this.statisticsParams.timeslotsSelected.map((timeslotName) => [
              this.venueData.timeslots[timeslotName][0][0] + ':' + this.venueData.timeslots[timeslotName][0][1],
            this.venueData.timeslots[timeslotName][1][0] + ':' + this.venueData.timeslots[timeslotName][1][1]
          ]),
        }
      }
    };
    const result = await this.httpClient
        .post(this.apiUrl + '/venues/exportGrafanaData', payload)
        .toPromise();
    this.exportInProgress = false;
    this.restaurantsService.exportXLSX(result, fileName);
  }
}
